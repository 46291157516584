import React from "react";
import TalkingCamel from "../../../api/TalkingCamel";
import {Animated} from "react-animated-css";
import ReactDOM from "react-dom";
import Popup from "../../popup/Popup";
import Loading from "../../loading/Loading";

import {stateToHTML} from "draft-js-export-html";
import {convertFromRaw} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Button from "@material-ui/core/Button";
import draftToHtml from "draftjs-to-html";

class SpravaOdpovediHistorie extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showRequestsHistory: false,
            showDialogHistory: false,
            loading: false,
            historyDetail: [],
            historizedNewDialogs: undefined
        };
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    predicateBy = (prop) => {
        return function (a, b) {
            if (a[prop] > b[prop]) {
                return 1;
            } else if (a[prop] < b[prop]) {
                return -1;
            }
            return 0;
        };
    };

    componentDidMount = () => {
        this.getHistory();
        this.getHistorizedNewDialog();
    };

    componentDidUpdate = () => {
    };

    getHistory = () => {
        TalkingCamel.get(
            "/api/dialog-texts/v2/" +
            this.props.project +
            "/" +
            this.props.dialogShortId +
            "/history"
        )
            .then((response) => {
                this.setState({
                    historyDetail: response.data,
                    show: true,
                    loading: false,
                });
            })
            .catch((error) => {
                let errorMessage = "";
                if (error) {
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data[0]) {
                                if (error.response.data[0].error) {
                                    errorMessage = error.response.data[0].error;
                                } else {
                                    errorMessage = "Unknown Error SpravaOdpovedi History";
                                }
                            } else {
                                errorMessage = "Unknown Error SpravaOdpovedi History";
                            }
                        } else {
                            errorMessage = "Unknown Error SpravaOdpovedi History";
                        }
                    } else {
                        errorMessage = "Unknown Error SpravaOdpovedi History";
                    }
                } else {
                    errorMessage = "Unknown Error SpravaOdpovedi History";
                }
                ReactDOM.render(
                    <Popup type="error" text={errorMessage}/>,
                    document.getElementById("popup-window")
                );
            });
    };

    getHistorizedNewDialog = () => {
        TalkingCamel.get(
            "/api/dialog-texts/v2/" +
            this.props.project +
            "/" +
            this.props.dialogShortId +
            "/newDialog/history"
        )
            .then((response) => {
                this.setState({
                    historizedNewDialog: response.data,
                    show: true,
                    loading: false,
                });
            })
            .catch((error) => {
                let errorMessage = "";
                if (error) {
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data[0]) {
                                if (error.response.data[0].error) {
                                    errorMessage = error.response.data[0].error;
                                } else {
                                    errorMessage = "Unknown Error SpravaOdpovedi History";
                                }
                            } else {
                                errorMessage = "Unknown Error SpravaOdpovedi History";
                            }
                        } else {
                            errorMessage = "Unknown Error SpravaOdpovedi History";
                        }
                    } else {
                        errorMessage = "Unknown Error SpravaOdpovedi History";
                    }
                } else {
                    errorMessage = "Unknown Error SpravaOdpovedi History";
                }
                ReactDOM.render(
                    <Popup type="error" text={errorMessage}/>,
                    document.getElementById("popup-window")
                );
            });
    }

    parseHistory = () => {
        if (this.state.historyDetail.length) {
            let data = this.state.historyDetail;
            let i = 0;
            const dialogHistoryDetail = data
                .filter((dataf) => {
                    const dialogNodeId = this.props.dialogNodeId;
                    if (dialogNodeId === dataf.nodeId) {
                        return true;
                    } else {
                        return false;
                    }
                })
                .map((data) => {
                    i++;
                    let i2 = 0;
                    const originDialog = data["origin-dialog"];
                    let contents = "No content"
                    if (originDialog.content)
                        contents = originDialog.content.map((data) => {
                            i2++;
                            if (data.response_type === "IMAGE") {
                                return (
                                    <div
                                        key={"i_" + i2}
                                        className="dialogDetailImage row dialogDetailMessage"
                                    >
                                        <div className="col">
                                            <img alt="" src={data.source}/>
                                        </div>
                                    </div>
                                );
                            } else if (data.response_type === "TEXT") {
                                return (
                                    <div
                                        key={"t1_" + i2}
                                        className="dialogDetailText row dialogDetailMessage"
                                    >
                                        <div className="col">
                                            <p>{data.text}</p>
                                        </div>
                                        <div className="chatAvatar">
                                            <img
                                                alt=""
                                                className=""
                                                src="https://addai.life/wp-content/uploads/2020/05/Webchat-icon.png"
                                            />
                                        </div>
                                    </div>
                                );
                            } else if (data.response_type === "OPTION") {
                                let i3 = 0;
                                const detailOption = data.options.map((data) => {
                                    i3++;
                                    return (
                                        <div key={"op_" + i3} className="option dialogDetailOption">
                                            <p>{data.label}</p>
                                        </div>
                                    );
                                });
                                if (data.title) {
                                    return (
                                        <div key={"o_" + i2} className="row">
                                            <div
                                                key={"t2_" + i2}
                                                className="dialogDetailText row dialogDetailMessage"
                                            >
                                                <div className="col">
                                                    <p>{data.title}</p>
                                                </div>
                                                <div className="chatAvatar">
                                                    <img
                                                        alt=""
                                                        className=""
                                                        src="https://addai.life/wp-content/uploads/2020/05/Webchat-icon.png"
                                                    />
                                                </div>
                                            </div>
                                            <div className="dialogDetailOptions row">{detailOption}</div>
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div key={"o_" + i2} className="">
                                            <div className="">
                                                <div className="dialogDetailOptions row">{detailOption}</div>
                                            </div>
                                        </div>
                                    );
                                }
                            }
                            return "";
                        });
                    const dialogComments = this.parseComments(data, i);
                    return (
                        <div className="resolved-comments" key={"requestResolved" + i}>
                            <div className="container">
                                <div className="row  requestResolvedRow">
                                    <div className="col">
                                        <b>{"Status: " + data.status}</b>
                                    </div>
                                    <div className="col">
                                        {"Requested at: " +
                                        this.getDateTime(Date.parse(data["requested-at"]))}
                                    </div>
                                    <div className="col">
                                        {"Resolved at: " +
                                        this.getDateTime(Date.parse(data["resolved-at"]))}
                                    </div>
                                    <div className="col">
                                        <b>{"For version: " + data["version"]}</b>
                                    </div>
                                </div>
                            </div>
                            <div className="container">{contents}</div>
                            <div key={"dialogComments" + i} className="container">
                                {data.comments ? dialogComments : "No comments"}
                            </div>
                        </div>
                    );
                    //return contents;
                });
            return dialogHistoryDetail;
        }
    };
    createMarkup = (text) => {
        return {__html: draftToHtml(JSON.parse(text))};
    };

    parseComments = (data, index) => {
        if (data.comments) {
            let i = index;
            let datac = data.comments.sort(this.predicateBy("commented-at"));
            let datalength = datac.length;
            let i4 = 0;
            return datac.map((data) => {
                i4++;
                let timestamp = this.getDateTime(Date.parse(data["commented-at"]));
                let author = data.author;
                if (datalength === i && this.props.username === data.author) {
                    return (
                        <Animated
                            key={"dialogCommentsa" + i4}
                            animationIn="fadeIn"
                            animationOut="fadeOut"
                            animationInDuration={1000}
                            animationOutDuration={1000}
                            isVisible={this.state.showDetail}
                        >
                            <div
                                key={"dialogCommentsb" + i4}
                                className="row comment commentLast commentedByMe"
                            >
                                <div className="col-1 commentAuthor">
                                    {this.renderAvatar("ByMe", author)}
                                </div>
                                {this.convertCommentFromJSONToHTML(
                                    data.text,
                                    timestamp,
                                    author
                                )}
                            </div>
                            <div className="col-1"></div>
                        </Animated>
                    );
                } else if (this.props.username !== data.author) {
                    return (
                        <div key={"dialogCommentsc" + i4} className="row comment">
                            <div className="col-1"></div>
                            {this.convertCommentFromJSONToHTML(
                                data.text,
                                timestamp,
                                author
                            )}
                            <div className="col-1 commentAuthor">
                                {this.renderAvatar("NotMe", author)}
                            </div>
                            <div className="col-1"></div>
                        </div>
                    );
                } else {
                    return (
                        <div
                            key={"dialogCommentsd" + i4}
                            className="row comment commentedByMe"
                        >
                            <div className="col-1 commentAuthor ">
                                {this.renderAvatar("ByMe", author)}
                            </div>
                            {this.convertCommentFromJSONToHTML(
                                data.text,
                                timestamp,
                                author
                            )}
                            <div className="col-1"></div>
                            <div className="col-1"></div>
                        </div>
                    );
                }
            });
        }
    }

    convertContentToHTML = (text) => {
        if (this.isJson(text)) {
            return (
                <div
                    dangerouslySetInnerHTML={this.createMarkup(text)}
                ></div>
            );
        } else {
            return <div>{text}</div>;
        }
    };

    parseHistorizedNewDialog = () => {
        if (this.state.historizedNewDialog) {
            return (
                <div className="container">
                    <React.Fragment>
                        <div className="row  requestResolvedRow">
                            <div className="col">
                                <b>{"Title: " + this.state.historizedNewDialog.title}</b>
                            </div>
                            <div className="col">
                                {"Created at: " +
                                this.getDateTime(Date.parse(this.state.historizedNewDialog.createdAt))}
                            </div>
                            <div className="col">
                                {"AcceptedAt at: " +
                                this.getDateTime(Date.parse(this.state.historizedNewDialog.acceptedAt))}
                            </div>
                        </div>
                        <div className="row">
                            <h6>Questions:</h6>
                        </div>
                        <div className="row">
                            {this.convertContentToHTML(this.state.historizedNewDialog.question)}
                        </div>
                        <div className="row">
                            <h6>Answers:</h6>
                        </div>
                        <div className="row">
                            {this.convertContentToHTML(this.state.historizedNewDialog.answer)}
                        </div>
                        <div key={Math.random()} className="container">
                            {this.state.historizedNewDialog.comments ? this.parseComments(this.state.historizedNewDialog, 0) : "No comments"}
                        </div>
                    </React.Fragment>
                </div>
            )
        }
    };

    renderAvatar = (user, author) => {
        //let avatarImg = "";
        if (author.includes("@addai.cz") || author === "admin") {
            return (
                <div className="chatAvatar">
                    <img
                        alt=""
                        className=""
                        src="https://addai.life/wp-content/uploads/2020/05/Webchat-icon.png"
                    />
                </div>
            );
        } else {
            return (
                <div className="chatAvatar noOutline">
                    <img alt="" className="" src="profile.png"/>
                </div>
            );
        }
    };

    parseResponseKomentar = () => {
        if (this.props.comments) {
            let data = this.props.comments.sort(this.predicateBy("commented-at"));
            let datalength = data.length;
            let i = 0;
            const dialogComments = data.map((data) => {
                i++;
                let timestamp = this.getDateTime(Date.parse(data["commented-at"]));
                let author = data.author;
                if (datalength === i && this.props.username === data.author) {
                    return (
                        <Animated
                            key={"dialogCommentsa" + i}
                            animationIn="fadeIn"
                            animationOut="fadeOut"
                            animationInDuration={1000}
                            animationOutDuration={1000}
                            isVisible={this.state.showDetail}
                        >
                            <div
                                key={"dialogCommentsb" + i}
                                className="row comment commentLast commentedByMe"
                            >
                                <div className="col-1 commentAuthor">
                                    {this.renderAvatar("ByMe", author)}
                                </div>
                                {this.convertCommentFromJSONToHTML(
                                    data.text,
                                    timestamp,
                                    author
                                )}
                            </div>
                            <div className="col-1"></div>
                        </Animated>
                    );
                } else if (this.props.username !== data.author) {
                    return (
                        <div key={"dialogCommentsc" + i} className="row comment">
                            <div className="col-1"></div>
                            {this.convertCommentFromJSONToHTML(data.text, timestamp, author)}
                            <div className="col-1 commentAuthor">
                                {this.renderAvatar("NotMe", author)}
                            </div>
                            <div className="col-1"></div>
                        </div>
                    );
                } else {
                    return (
                        <div
                            key={"dialogCommentsd" + i}
                            className="row comment commentedByMe"
                        >
                            <div className="col-1 commentAuthor ">
                                {this.renderAvatar("ByMe", author)}
                            </div>
                            {this.convertCommentFromJSONToHTML(data.text, timestamp, author)}
                            <div className="col-1"></div>
                            <div className="col-1"></div>
                        </div>
                    );
                }
            });
            return (
                <div key={"dialogComments" + i} className="container">
                    {dialogComments}
                </div>
            );
        } else return <div>No comments</div>;
    };

    toggleRequestsHistory = () => {
        this.setState((prevState) => ({
            showRequestsHistory: prevState.showRequestsHistory !== true,
        }));
    };

    toggleDialogHistory = () => {
        this.setState((prevState) => ({
            showDialogHistory: prevState.showDialogHistory !== true,
        }));
    };

    convertCommentFromJSONToHTML = (text, timestamp, author) => {
        if (this.isJson(text)) {
            let parsedText = {
                __html: stateToHTML(convertFromRaw(JSON.parse(text))),
            };
            return (
                <div className="col-10 messageBubble">
                    <div
                        className="commentText"
                        dangerouslySetInnerHTML={parsedText}
                    ></div>
                    <div className="messageTime">{timestamp}</div>
                    <div className="messageAuthor">{author}</div>
                </div>
            );
        } else {
            return (
                <div className="col-10 messageBubble">
                    <div className="commentText">{text}</div>
                    <div className="messageTime">{timestamp}</div>
                    <div className="messageAuthor">{author}</div>
                </div>
            );
        }
    };

    isJson = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    };

    getDateTime = (data) => {
        if (data) {
            data = data + 2 * 60 * 60 * 1000;
            return new Intl.DateTimeFormat("en-GB", {
                month: "numeric",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
            }).format(data);
        } else return "Error on time parsing";
    };

    renderHistoryRequestsShowButton = () => {
        return (
            <div>
                <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={this.toggleRequestsHistory}
                >
                    {!this.state.showHistory ? "Show Requests History" : "Hide History"}
                </Button>
            </div>
        );
    };

    renderDialogHistoryShowButton = () => {
        return (
            <div>
                <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={this.toggleDialogHistory}
                >
                    {!this.state.showHistory ? "Show Dialog History" : "Hide History"}
                </Button>
            </div>
        );
    };

    getRequestsHistoryView = () => {
        if (!this.state.showRequestsHistory) {
            return (
                <div>
                    {this.renderHistoryRequestsShowButton()}
                </div>
            );
        } else {
            if (this.state.loading) {
                return (
                    <div>
                        {this.renderHistoryRequestsShowButton()}
                        <Loading/>
                    </div>
                );
            } else {
                return (
                    <div>
                        {this.renderHistoryRequestsShowButton()}
                        {this.parseHistory()}
                        {this.state.historyDetail.length === 0
                        && <div className="no-history">
                            No results for this dialog and platform. Go out there and make History! :)}
                        </div>
                        }
                    </div>
                );
            }
        }
    }

    getDialogHistoryView = () => {
        if (!this.state.showDialogHistory) {
            return (
                <div>
                    {this.renderDialogHistoryShowButton()}
                </div>
            );
        } else {
            if (this.state.loading) {
                return (
                    <div>
                        {this.renderDialogHistoryShowButton()}
                        <Loading/>
                    </div>
                );
            } else {
                return (
                    <div>
                        {this.renderDialogHistoryShowButton()}
                        {this.parseHistorizedNewDialog()}
                        {!this.state.historizedNewDialog
                        && <div className="no-history">
                            No results for this dialog and platform. Go out there and make History! :)
                        </div>
                        }
                    </div>
                );
            }
        }
    }

    render() {
        return (
            <div className="SpravaOdpovediHistory container">
                {this.getRequestsHistoryView()}
                {this.getDialogHistoryView()}
            </div>
        )
    }
}

export default SpravaOdpovediHistorie;
