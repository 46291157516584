import React from "react";
import TalkingCamel from "../../../../api/TalkingCamel";
import "../SpravaOdpovediNewDialog.scss";
import ReactDOM from "react-dom";
import Popup from "../../../popup/Popup";
import {Animated} from "react-animated-css";

import {ContentState, convertToRaw, EditorState} from "draft-js";
import {Editor} from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

class SpravaOdpovediNewDialogList extends React.Component {
    constructor(props) {
        super(props);
        const html = "";
        const contentBlock = htmlToDraft(html);
        this.state = {
            commentText: "",
            editorState: EditorState.createEmpty(),
            dialogIdNew: this.props.dialogNewShortDialogId,
        };
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(
                contentBlock.contentBlocks
            );
            const editorState = EditorState.createWithContent(contentState);
            this.state = {
                editorState,
            };
        }
    }

    componentDidMount = () => {
        this.setState({
            dialogIdNew: this.props.dialogNewShortDialogId
        })
    };

    componentDidUpdate = (prevProps) => {
    };

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    isJson = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    };

    convertCommentFromJSONToHTML = (text, timestamp, author) => {
        if (this.isJson(text)) {
            const result = draftToHtml(JSON.parse(text)).replaceAll(/<p><\/p>/g, '<br>');
            return (
                <div className="col-10 messageBubble">
                    <div
                        className="commentText"
                        dangerouslySetInnerHTML={{__html: result}}
                    ></div>
                    <div className="messageTime">{timestamp}</div>
                    <div className="messageAuthor">{author}</div>
                </div>
            );
        } else {
            return (
                <div className="col-10 messageBubble">
                    <div className="commentText">{text}</div>
                    <div className="messageTime">{timestamp}</div>
                    <div className="messageAuthor">{author}</div>
                </div>
            );
        }
    };

    convertContentToHTML = (text) => {
        if (this.isJson(text)) {
            const result = draftToHtml(JSON.parse(text)).replaceAll(/<p><\/p>/g, '<br>');
            return (
                <div
                    className="commentText"
                    dangerouslySetInnerHTML={{__html: result}}
                ></div>
            );
        } else {
            return <div className="commentText">{text}</div>;
        }
    };

    RemoveSODMNNode = () => {
        TalkingCamel.delete(
            "/api/dialog-texts/v2/" +
            this.props.project +
            "/dialog-new/" +
            this.props.dialogNewShortId
        )
            .then((response) => {
                this.props.getSODialogNewPreview();
                console.log(response);
                this.setState({
                    dialogShort: response.data,
                    loading: false,
                    projectPrev: this.state.project,
                });
                this.props.showDetail();
            })
            .catch((error) => {
                console.log(error);
                let errorMessage = "";
                if (error) {
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data[0]) {
                                if (error.response.data[0].error) {
                                    errorMessage = error.response.data[0].error;
                                } else {
                                    errorMessage = "Unknown Error SpravaOdpovedi";
                                }
                            } else {
                                errorMessage = "Unknown Error SpravaOdpovedi";
                            }
                        } else {
                            errorMessage = "Unknown Error SpravaOdpovedi";
                        }
                    } else {
                        errorMessage = "Unknown Error SpravaOdpovedi";
                    }
                } else {
                    errorMessage = "Unknown Error SpravaOdpovedi";
                }
                ReactDOM.render(
                    <Popup type="error" text={errorMessage}/>,
                    document.getElementById("popup-window")
                );
            });
    };

    addSODMNComment = (e) => {
        e.preventDefault();
        let text = JSON.stringify(
            convertToRaw(this.state.editorState.getCurrentContent())
        );
        TalkingCamel.post(
            "/api/dialog-texts/v2/" +
            this.props.project +
            "/dialog-new/" +
            this.props.dialogNewShortId +
            "/comment",
            {
                text: text,
            }
        )
            .then((response) => {
                this.props.getSODialogNewPreview();
                console.log(response);
                this.setState({
                    dialogShort: response.data,
                    loading: false,
                    projectPrev: this.state.project,
                    editorState: EditorState.createEmpty(),
                    contentState: {},
                });
            })
            .catch((error) => {
                console.log(error);
                let errorMessage = "";
                if (error) {
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data[0]) {
                                if (error.response.data[0].error) {
                                    errorMessage = error.response.data[0].error;
                                } else {
                                    errorMessage = "Unknown Error SpravaOdpovedi";
                                }
                            } else {
                                errorMessage = "Unknown Error SpravaOdpovedi";
                            }
                        } else {
                            errorMessage = "Unknown Error SpravaOdpovedi";
                        }
                    } else {
                        errorMessage = "Unknown Error SpravaOdpovedi";
                    }
                } else {
                    errorMessage = "Unknown Error SpravaOdpovedi";
                }
                ReactDOM.render(
                    <Popup type="error" text={errorMessage}/>,
                    document.getElementById("popup-window")
                );
            });
    };

    renderAvatar = (user, author) => {
        if (author.includes("@addai.cz") || author === "admin") {
            return (
                <div className="chatAvatar">
                    <img
                        alt=""
                        className=""
                        src="https://addai.life/wp-content/uploads/2020/05/Webchat-icon.png"
                    />
                </div>
            );
        } else {
            return (
                <div className="chatAvatar noOutline">
                    <img alt="" className="" src="profile.png"/>
                </div>
            );
        }
    };

    predicateBy = (prop) => {
        return function (a, b) {
            if (a[prop] > b[prop]) {
                return 1;
            } else if (a[prop] < b[prop]) {
                return -1;
            }
            return 0;
        };
    };

    getDateTime = (data) => {
        if (data) {
            data = data + 2 * 60 * 60 * 1000;
            return new Intl.DateTimeFormat("en-GB", {
                month: "numeric",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
            }).format(data);
        } else return "Error on time parsing";
    };

    parseResponseKomentar = () => {
        if (this.props.comments) {
            let data = this.props.comments.sort(this.predicateBy("commented-at"));
            //console.log(data);
            let datalength = data.length;
            let i = 0;
            const dialogDetail = data.map((data) => {
                i++;
                let timestamp = this.getDateTime(Date.parse(data["commented-at"]));
                let author = data.author;
                if (datalength === i && this.props.username === data.author) {
                    return (
                        <Animated
                            key={"dialogDetaila" + i}
                            animationIn="fadeIn"
                            animationOut="fadeOut"
                            animationInDuration={1000}
                            animationOutDuration={1000}
                            isVisible={this.state.showDetail}
                        >
                            <div
                                key={"dialogDetailb" + i}
                                className="row comment commentLast commentedByMe"
                            >
                                <div className="col-1 commentAuthor">
                                    {this.renderAvatar("ByMe", author)}
                                </div>
                                {this.convertCommentFromJSONToHTML(
                                    data.text,
                                    timestamp,
                                    author
                                )}
                                {this.renderDeleteCommentButton(data)}
                            </div>
                            <div className="col-1"></div>
                        </Animated>
                    );
                } else if (this.props.username !== data.author) {
                    return (
                        <div key={"dialogDetailc" + i} className="row comment">
                            <div className="col-1"></div>
                            {this.convertCommentFromJSONToHTML(data.text, timestamp, author)}
                            <div className="col-1 commentAuthor">
                                {this.renderAvatar("NotMe", author)}
                            </div>
                            <div className="col-1"></div>
                        </div>
                    );
                } else {
                    return (
                        <div
                            key={"dialogDetaild" + i}
                            className="row comment commentedByMe"
                        >
                            <div className="col-1 commentAuthor ">
                                {this.renderAvatar("ByMe", author)}
                            </div>
                            {this.convertCommentFromJSONToHTML(data.text, timestamp, author)}
                            <div className="col-1"></div>
                            <div className="col-1"></div>
                        </div>
                    );
                }
            });
            return (
                <div key={"dialogDetail" + i} className="container">
                    {dialogDetail}
                </div>
            );
        } else return <div>No comments</div>;
    };

    addCommentBlock = () => {
        const {editorState} = this.state;
        return (
            <form onSubmit={this.addSODMNComment}>
                <Editor
                    editorState={editorState}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    onEditorStateChange={this.onEditorStateChange}
                />
                <Button type="submit" variant="contained" size="large" color="primary">
                    Add Comment
                </Button>
            </form>
        );
    };

    renderDeleteCommentButton = (data) => {
        if (this.props.userRole.includes("CUSTOMER_READER:" + this.props.project)) {
            return "";
        } else {
            return (
                <div className="col-1 close-wrap">
                    <button
                        type="button"
                        className={this.state.check ? "close green" : "close red"}
                        aria-label="Close"
                        value={data.id}
                        onClick={this.deleteKomentar}
                    >
                        {this.state.check ? "V" : "X"}
                    </button>
                </div>
            );
        }
    };

    deleteKomentar = (e) => {
        if (this.state.check) {
            TalkingCamel.delete(
                "/api/dialog-texts/v2/" +
                this.props.project +
                "/dialog-new/" +
                this.props.dialogNewShortId +
                "/comment/" +
                e.target.value
            )
                .then((response) => {
                    this.props.getSODialogNewPreview();
                })
                .catch((error) => {
                    console.log(error);
                    let errorMessage = "";
                    if (error) {
                        if (error.response) {
                            if (error.response.data) {
                                if (error.response.data[0]) {
                                    if (error.response.data[0].error) {
                                        errorMessage = error.response.data[0].error;
                                    } else {
                                        errorMessage = "Unknown Error SpravaOdpovedi";
                                    }
                                } else {
                                    errorMessage = "Unknown Error SpravaOdpovedi";
                                }
                            } else {
                                errorMessage = "Unknown Error SpravaOdpovedi";
                            }
                        } else {
                            errorMessage = "Unknown Error SpravaOdpovedi";
                        }
                    } else {
                        errorMessage = "Unknown Error SpravaOdpovedi";
                    }
                    ReactDOM.render(
                        <Popup type="error" text={errorMessage}/>,
                        document.getElementById("popup-window")
                    );
                });
        } else this.setState({check: true});
    };

    acceptNewDialog = () => {
        if (this.state.dialogIdNew) {
            TalkingCamel.put(
                "/api/dialog-texts/v2/" +
                this.props.project +
                "/dialog-new/" +
                this.props.dialogNewShortId +
                "/accept",
                {
                    dialogId: this.state.dialogIdNew,
                }
            )
                .then((response) => {
                    this.props.getSODialogNewPreview();
                    this.props.showDetail();
                    ReactDOM.render(
                        <Popup type="correct" text="Dialog node Accepted! good job :)."/>,
                        document.getElementById("popup-window")
                    );
                })
                .catch((error) => {
                    let errorMessage = "Unknown error occurred";
                    console.log(error);
                    if (error)
                        if (error.response)
                            if (error.response.data)
                                if (error.response.data[0])
                                    errorMessage = error.response.data[0].error;
                    ReactDOM.render(
                        <Popup type="error" text={errorMessage}/>,
                        document.getElementById("popup-window"));
                });
        } else {
            this.setState({errorAddNode: true});
        }
    };

    render() {
        return (
            <div className="container SpravaOdpovediNewDialogList">
                <div className="container">
                    <div className="row row-detail">
                        <div className="SpravaOdpovediNedDialogEditDetail-wrap col">
                            <h3 className="mainTitle">New dialogue request</h3>
                            <div className="container">
                                <div className="row ">
                                    <div className="col">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col">
                                                    <h4>Title:</h4>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col showDataRow">
                                                    {this.props.dialogNewShortTitle}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col ">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col">
                                                    <h4>System ID:</h4>
                                                </div>
                                            </div>
                                            <div className="row showDataRow">
                                                <div className="col">{this.props.dialogNewShortId}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row ">
                                    <div className="col">
                                        <h4>Question:</h4>
                                    </div>
                                </div>
                                <div className="row ">
                                    <div className="col showDataRow">
                                        {this.convertContentToHTML(
                                            this.props.dialogNewShortQuestion
                                        )}
                                    </div>
                                </div>
                                <div className="row ">
                                    <div className="col ">
                                        <h4>Answer:</h4>
                                    </div>
                                </div>
                                <div className="row ">
                                    <div className="col showDataRow">
                                        {this.convertContentToHTML(this.props.dialogNewShortAnswer)}
                                    </div>
                                </div>
                                <div className="row ">
                                    <div className="col ">
                                        <h4>Created at:</h4>
                                    </div>
                                </div>
                                <div className="row ">
                                    <div className="col showDataRow">
                                        {this.getDateTime(
                                            Date.parse(this.props.dialogNewShortCreatedAt)
                                        )}
                                    </div>
                                </div>
                                <div className="row commentsTitle">
                                    <div className="col">
                                        <h4>Comments</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">{this.parseResponseKomentar()}</div>
                                </div>
                                <div className="row comments">
                                    <div className="col">
                                        {this.props.userRole.includes(
                                            "CUSTOMER_EDITOR:" + this.props.project
                                        ) ||
                                        this.props.userRole.includes(
                                            "EDITOR:" + this.props.project
                                        ) ||
                                        this.props.userRole.includes("ADMIN")
                                            ? this.addCommentBlock()
                                            : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.props.userRole.includes("ADMIN") ||
                this.props.userRole.includes("EDITOR:" + this.props.project) ? (
                    <div className="container">
                        <div className="row actionsTitle">
                            <div className="col">
                                <h4>Acceptace</h4>
                            </div>
                        </div>
                        <div className="row actions">
                            <div className="col">
                                <TextField
                                    error={this.state.errorAddNode ? true : false}
                                    defaultValue={this.props.dialogNewShortDialogId}
                                    required
                                    label="Dialogue ID"
                                    helperText='Insert new dialogue ID e.g. "f125"'
                                    onChange={(e) =>
                                        this.setState({
                                            dialogIdNew: e.target.value,
                                            errorAddNode: false,
                                        })
                                    }
                                />
                            </div>
                            <div className="col">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    onClick={this.RemoveSODMNNode}
                                    color="primary"
                                    className="red"
                                >
                                    Delete this dialog
                                </Button>
                            </div>
                            <div className="col">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    onClick={this.acceptNewDialog}
                                    color="primary"
                                >
                                    Accept new dialog
                                </Button>
                            </div>
                        </div>
                    </div>
                ) : (
                    ""
                )}
            </div>
        );
    }
}

export default SpravaOdpovediNewDialogList;
