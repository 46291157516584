import React from "react";
import "./SpravaOdpovediKomentar.scss";
import TalkingCamel from "../../../api/TalkingCamel";
import { Animated } from "react-animated-css";
import ReactDOM from "react-dom";
import Popup from "../../../components/popup/Popup";
import GetProjectVersion from "../../getProjectVersion/GetProjectVersion";
import SpravaOdpovediHistorie from "../spravaOdpovediHistorie/SpravaOdpovediHistorie";

import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import Button from "@material-ui/core/Button";

class SpravaOdpovediKomentar extends React.Component {
  constructor(props) {
    super(props);
    const html = "";
    const contentBlock = htmlToDraft(html);
    this.state = {
      term: "",
      show: true,
      comments: [],
      check: false,
      versionProd: "",
      versionDev: "",
      selectedVersion: "",
      projectPrev: "",
      changeRequestOptions: "EMPTY",
      changeRequestVersion: "",
      dialogNodeId: "",
      dialogDetail: this.props.dialogDetail,
      updateCommentsBool: false
    };
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      this.state = {
        editorState,
      };
    }
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  predicateBy = (prop) => {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  };

  componentDidMount = () => {
    this.getProjectVersion();
  };

  componentDidUpdate = () => {
    if (this.state.project !== this.state.projectPrev) {
      this.getProjectVersion();
    }
    if (document.getElementById("SpravaOdpovediKomentare")) {
      var objDiv = document.getElementById("SpravaOdpovediKomentare");
      objDiv.scrollTop = objDiv.scrollHeight;
    }
  };

  isJson = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  convertContentToHTML = (text, timestamp, author) => {
    if (this.isJson(text)) {
      const result = draftToHtml(JSON.parse(text)).replaceAll(/<p><\/p>/g, '<br>');
      return (
        <div className="col-10 messageBubble">
          <div
            className="commentText"
            dangerouslySetInnerHTML={ {__html: result}}
          >
          </div>
          <div className="messageTime">{timestamp}</div>
          <div className="messageAuthor">{author}</div>
        </div>
      );
    } else {
      return (
        <div className="col-10 messageBubble">
          <div className="commentText">{text}</div>
          <div className="messageTime">{timestamp}</div>
          <div className="messageAuthor">{author}</div>
        </div>
      );
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      project: nextProps.project,
    };
  }

  getProjectVersion = () => {
    this.setState({
      versionProd: GetProjectVersion(this.state.project, this.state.projectPrev)
          .dataVersionProduction,
      versionDev: GetProjectVersion(this.state.project, this.state.projectPrev).dataVersionDevelopment,
      selectedVersion: GetProjectVersion(
        this.state.project,
        this.state.projectPrev
      ).dataVersionDevelopment,
      projectPrev: this.state.project,
    });
  };

  getDateTime = (data) => {
    if (data) {
      data = data + 2 * 60 * 60 * 1000;
      return new Intl.DateTimeFormat("en-GB", {
        month: "numeric",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      }).format(data);
    } else return "Error on time parsing";
  };

  toggleComment = () => {
    this.setState(() => ({
      showComment: this.state.showComment === true ? false : true,
    }));
  };

  renderDeleteCommentButton = (data) => {
    if (this.props.userRole.includes("CUSTOMER_READER:" + this.props.project)) {
      return "";
    } else {
      return (
        <div className="col-1 close-wrap">
          <button
            type="button"
            className={this.state.check ? "close green" : "close red"}
            aria-label="Close"
            value={data.id}
            onClick={this.deleteKomentar}
          >
            {this.state.check ? "V" : "X"}
          </button>
        </div>
      );
    }
  };

  parseResponseResolvedKomentar = () => {
    if (this.props.resolvedComments) {
      let data = this.props.resolvedComments;
      let i = 0;
      const dialogDialogDetail = data.map((data) => {
        i++;
        let datac = data.comments.sort(this.predicateBy("commented-at"));
        let datalength = datac.length;
        let i4 = 0;
        const dialogComments = datac.map((data) => {
          i4++;
          let timestamp = this.getDateTime(Date.parse(data["commented-at"]));
          let author = data.author;
          if (datalength === i && this.props.username === data.author) {
            return (
              <Animated
                key={"dialogCommentsa" + i4}
                animationIn="fadeIn"
                animationOut="fadeOut"
                animationInDuration={1000}
                animationOutDuration={1000}
                isVisible={this.state.showDetail}
              >
                <div
                  key={"dialogCommentsb" + i4}
                  className="row comment commentLast commentedByMe"
                >
                  <div className="col-1 commentAuthor">
                    {this.renderAvatar("ByMe", author)}
                  </div>
                  {this.convertContentToHTML(data.text, timestamp, author)}
                </div>
                <div className="col-1"></div>
              </Animated>
            );
          } else if (this.props.username !== data.author) {
            return (
              <div key={"dialogCommentsc" + i4} className="row comment">
                <div className="col-1"></div>
                {this.convertContentToHTML(data.text, timestamp, author)}
                <div className="col-1 commentAuthor">
                  {this.renderAvatar("NotMe", author)}
                </div>
                <div className="col-1"></div>
              </div>
            );
          } else {
            return (
              <div
                key={"dialogCommentsd" + i4}
                className="row comment commentedByMe"
              >
                <div className="col-1 commentAuthor ">
                  {this.renderAvatar("ByMe", author)}
                </div>
                {this.convertContentToHTML(data.text, timestamp, author)}
                <div className="col-1"></div>
                <div className="col-1"></div>
              </div>
            );
          }
        });

        return (
          <div className="resolved-comments" key={"requestResolved" + i}>
            <div className="container">
              <div>
                <h5>Marked as resolved (this version only)</h5>
              </div>
              <div className="row  requestResolvedRow">
                <div className="col">
                  <b>{"Status: " + data["change-request-status"]}</b>
                </div>
                <div className="col">
                  {"Requested at: " +
                    this.getDateTime(Date.parse(data["requested-at"]))}
                </div>
                <div className="col">
                  {"Resolved at: " +
                    this.getDateTime(Date.parse(data["resolved-at"]))}
                </div>
                <div className="col">
                  <b>{"For version: " + data["version"]}</b>
                </div>
              </div>
              <div key={"dialogComments" + i} className="container">
                {data.comments ? dialogComments : "No comments"}
              </div>
            </div>
          </div>
        );
      });
      return dialogDialogDetail;
    }
  };

  renderAvatar = (user, author) => {
//    let avatarImg = "";

    if (author.includes("@addai.cz") || author === "admin") {
      return (
        <div className="chatAvatar">
          <img
            alt=""
            className=""
            src="https://addai.life/wp-content/uploads/2020/05/Webchat-icon.png"
          />
        </div>
      );
    } else {
      return (
        <div className="chatAvatar noOutline">
          <img alt="" className="" src="profile.png" />
        </div>
      );
    }
  };

  parseResponseKomentar = () => {
    if (this.props.comments) {
      let data = this.props.comments.sort(this.predicateBy("commented-at"));
      let datalength = data.length;
      let i = 0;
      const dialogDetail = data.map((data) => {
        i++;
        let timestamp = this.getDateTime(Date.parse(data["commented-at"]));
        let author = data.author;
        if (datalength === i && this.props.username === data.author) {
          return (
            <Animated
              key={"dialogDetaila" + i}
              animationIn="fadeIn"
              animationOut="fadeOut"
              animationInDuration={1000}
              animationOutDuration={1000}
              isVisible={this.state.showDetail}
            >
              <div
                key={"dialogDetailb" + i}
                className="row comment commentLast commentedByMe"
              >
                <div className="col-1 commentAuthor">
                  {this.renderAvatar("ByMe", author)}
                </div>
                {this.convertContentToHTML(data.text, timestamp, author)}
                {this.renderDeleteCommentButton(data)}
              </div>
              <div className="col-1"></div>
            </Animated>
          );
        } else if (this.props.username !== data.author) {
          return (
            <div key={"dialogDetailc" + i} className="row comment">
              <div className="col-1"></div>
              {this.convertContentToHTML(data.text, timestamp, author)}
              <div className="col-1 commentAuthor">
                {this.renderAvatar("NotMe", author)}
              </div>
              <div className="col-1"></div>
            </div>
          );
        } else {
          return (
            <div
              key={"dialogDetaild" + i}
              className="row comment commentedByMe"
            >
              <div className="col-1 commentAuthor ">
                {this.renderAvatar("ByMe", author)}
              </div>
              {this.convertContentToHTML(data.text, timestamp, author)}
              <div className="col-1"></div>
              <div className="col-1"></div>
            </div>
          );
        }
      });
      return (
        <div key={"dialogDetail" + i} className="container">
          {dialogDetail}
        </div>
      );
    } else return <div>No comments</div>;
  };

  onFormSubmitKomentar = (e) => {
    const html = "";
    const contentBlock = htmlToDraft(html);
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    const editorState = EditorState.createWithContent(contentState);
    e.preventDefault();
    let text = JSON.stringify(
      convertToRaw(this.state.editorState.getCurrentContent())
    );
    TalkingCamel.post(
      "/api/dialog-texts/v2/" +
        this.props.project +
        "/" +
        this.props.dialogNodeId +
        "/comment",
      {
        text: text,
      }
    )
      .then((response) => {
        this.props.updateComments();
        this.setState({ editorState });
        this.props.updateStatus();
      })
      .catch((error) => {
        console.log(error);
        let errorMessage = "";
        if (error) {
          if (error.response) {
            if (error.response.data) {
              if (error.response.data[0]) {
                if (error.response.data[0].error) {
                  errorMessage = error.response.data[0].error;
                } else {
                  errorMessage =
                    "Unknown Error SpravaOdpovediKomentar onFormSubmitKomentar";
                }
              } else {
                errorMessage =
                  "Unknown Error SpravaOdpovediKomentar onFormSubmitKomentar";
              }
            } else {
              errorMessage =
                "Unknown Error SpravaOdpovediKomentar onFormSubmitKomentar";
            }
          } else {
            errorMessage =
              "Unknown Error SpravaOdpovediKomentar onFormSubmitKomentar";
          }
        } else {
          errorMessage =
            "Unknown Error SpravaOdpovediKomentar onFormSubmitKomentar";
        }
        ReactDOM.render(
          <Popup type="error" text={errorMessage} />,
          document.getElementById("popup-window")
        );
      });
  };

  deleteKomentar = (e) => {
    if (this.state.check) {
      TalkingCamel.delete(
        "/api/dialog-texts/v2/" +
          this.state.project +
          "/" +
          this.props.dialogNodeId +
          "/comment/" +
          e.target.value
      )
        .then((response) => {
          this.props.updateComments();
          this.setState({ check: false });
          this.props.updateStatus();
        })
        .catch((error) => {
          let errorMessage = "";
          !error.response.data[0].error
            ? (errorMessage =
                "Unknown Error SpravaOdpovediKomentar deleteKomentar")
            : (errorMessage = error.response.data[0].error);
          ReactDOM.render(
            <Popup type="error" text={errorMessage} />,
            document.getElementById("popup-window")
          );
        });
    } else this.setState({ check: true });
  };

  addCommentBlock = () => {
    const { editorState } = this.state;
    return (
      <form onSubmit={this.onFormSubmitKomentar}>
        <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={this.onEditorStateChange}
        />
        <Button type="submit" variant="contained" size="large" color="primary">
          Add Comment
        </Button>
      </form>
    );
  };

  changeRequestSubmit = (e, status) => {
    e.preventDefault();
    if (this.state.changeRequestOptions !== "EMPTY") {
      let changeRequestOptions = "";
      if (e.target.value) {
        changeRequestOptions = e.target.value;
      } else {
        changeRequestOptions = this.state.changeRequestOptions;
      }
      if (changeRequestOptions === "ACCEPTED") {
        TalkingCamel.put(
          "/api/dialog-texts/v2/" +
            this.props.project +
            "/" +
            this.props.dialogNodeId +
            "/resolve",
          {
            "change-request-status": "ACCEPTED",
            version: this.state.selectedVersion,
          }
        )
          .then((response) => {
            this.props.updateDetail();
            this.props.updateStatus();
            this.setState({ selectedVersion: this.state.versionDev });

            ReactDOM.render(
              <Popup
                type="success"
                text={"Change request was set to " + changeRequestOptions}
              />,
              document.getElementById("popup-window")
            );
          })
          .catch((error) => {
            let errorMessage = "";
            !error.response.data[0].error
              ? (errorMessage =
                  "Unknown Error SpravaOdpovediKomentar changeRequestSubmitAccept")
              : (errorMessage =
                  "Unknown Error SpravaOdpovediKomentar changeRequestSubmitAccept");
            ReactDOM.render(
              <Popup type="error" text={errorMessage} />,
              document.getElementById("popup-window")
            );
          });
      } else if (changeRequestOptions === "REJECTED" || status === "CANCELED") {
        TalkingCamel.put(
          "/api/dialog-texts/v2/" +
            this.props.project +
            "/" +
            this.props.dialogNodeId +
            "/resolve",
          {
            "change-request-status": changeRequestOptions || status,
          }
        )
          .then((response) => {
            this.props.updateDetail();
            this.props.updateStatus();

            ReactDOM.render(
              <Popup
                type="success"
                text={"Change request was set to " + changeRequestOptions}
              />,
              document.getElementById("popup-window")
            );
            this.setState({ changeRequestOptions: "ACCEPTED" });
            this.setState({ selectedVersion: this.state.versionDev });
          })
          .catch((error) => {
            let errorMessage = "";
            !error.response.data[0].error
              ? (errorMessage =
                  "Unknown Error SpravaOdpovediKomentar changeRequestSubmit" +
                  changeRequestOptions)
              : (errorMessage = error.response.data[0].error);
            ReactDOM.render(
              <Popup type="error" text={errorMessage} />,
              document.getElementById("popup-window")
            );
          });
      }
    }
  };

  addChangeRequest = () => {
    return (
      <div>
        {(this.props.userRole.includes("EDITOR:" + this.props.project) &&
          !this.props.userRole.includes(
            "CUSTOMER_EDITOR:" + this.props.project
          )) ||
        this.props.userRole.includes("ADMIN")
          ? this.addChangeRequestBlock()
          : ""}
        {this.props.userRole.includes("CUSTOMER_EDITOR:" + this.props.project)
          ? this.addChangeRequestBlockEditor()
          : ""}
      </div>
    );
  };

  addChangeRequestBlock = () => {
    return (
      <form onSubmit={this.changeRequestSubmit}>
        <select
          onChange={(e) =>
            this.setState({ changeRequestOptions: e.target.value })
          }
          className="form-control"
        >
          <option value="EMPTY">Select Acceptation status</option>
          <option value="ACCEPTED">Accepted</option>
          <option value="REJECTED">Rejected</option>
        </select>
        {this.state.changeRequestOptions === "ACCEPTED" ? (
          <select
            onChange={(e) => this.setState({ selectedVersion: e.target.value })}
            className="form-control"
          >
            <option value={this.state.versionDev}>
              Development: {this.state.versionDev}
            </option>
            <option value={this.state.versionProd}>
              Production: {this.state.versionProd}
            </option>
          </select>
        ) : (
          ""
        )}
        <Button type="submit" variant="contained" size="large" color="primary">
          Submit
        </Button>
      </form>
    );
  };

  addChangeRequestBlockEditor = () => {
    return (
      <Button
        type="submit"
        variant="contained"
        size="large"
        color="primary"
        onClick={(e) => this.changeRequestSubmit(e, "CANCELED")}
      >
        Cancel change request
      </Button>
    );
  };

  render() {
    return (
      <div className="SpravaOdpovediKomentar container">
        <div className="SpravaOdpovediPridatKomentar container">
          <div id="SpravaOdpovediKomentare" className="SpravaOdpovediKomentare">
            {this.props.resolvedComments ? this.parseResponseResolvedKomentar() : ""}
            {this.props.comments ? this.parseResponseKomentar() : ""}
          </div>
          {this.props.userRole.includes(
            "CUSTOMER_EDITOR:" + this.props.project
          ) ||
          this.props.userRole.includes("EDITOR:" + this.props.project) ||
          this.props.userRole.includes("ADMIN")
            ? this.addCommentBlock()
            : ""}
          {this.props.statusValue === "CHANGE_REQUEST_PROCESSED" ||
          this.props.statusValue === "OK"
            ? ""
            : this.addChangeRequest()}
        </div>
        <div>
          <SpravaOdpovediHistorie
            userRole={this.props.userRole}
            dialogShortId={this.props.dialogShortId}
            project={this.props.project}
            username={this.props.username}
            dialogNodeId={this.props.dialogNodeId}
          />
        </div>
      </div>
    );
  }
}
export default SpravaOdpovediKomentar;
